
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    heroContents () {
      return this.post.acf?.hero;
    },
    imagePosition () {
      return this.post.acf?.hero.image_position;
    },
    titleWords () {
      return this.heroContents.title.split(` `);
    },
  },
};
