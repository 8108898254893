import { render, staticRenderFns } from "./HeroVideo.vue?vue&type=template&id=285fb63c&scoped=true&"
import script from "./HeroVideo.vue?vue&type=script&lang=js&"
export * from "./HeroVideo.vue?vue&type=script&lang=js&"
import style0 from "./HeroVideo.vue?vue&type=style&index=0&id=285fb63c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285fb63c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Video: require('/src/components/Video/Video.vue').default})
