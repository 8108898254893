

export default {
  async asyncData ({
    route, env, store, $axios, $getPosts, $postMeta, $endpoints,
  }) {
    const queryParams = {
      fields: `id,title,excerpt,image,link,acf,yoast`,
      posts: {
        posts_per_page: 1, // Set to number of homepages
        post_type: `page`,
      },
    };

    const request = await $getPosts(`/front`, queryParams);

    if (env.multilanguage) {
      /*
      * If using multilanguage and posts_per_page > 1, retrieve all homepages
      * and then grab the correct post during mounted (when localstorage becomes available)
      */
      return {
        posts: request,
      };
    }

    return {
      meta: $postMeta(request[0]),
      post: request[0],
    };
  },
  data () {
    return {
      posts: [], // For multiple homepages
      post: {},
    };
  },
  computed: {
  },
  mounted () {
    if (process.env.multilanguage) {
      // localstorage now available so language has been set
      this.post = this.posts.find(item => item.lang === this.siteLanguage);
      this.meta = this.$postMeta(this.post);
    }
    this.setPostData(this.post, true);
    this.$nextTick(() => {
      const colourScheme = this.post?.acf?.use_commercial_colour_scheme;
      this.$store.dispatch(`wp/setColourScheme`, colourScheme);
    });
  },
  head () {
    return {
      title: `${process.env.siteTitle} - ${this.post.title}`,
      description: this.post.yoast ? this.post.yoast.description : this.post.excerpt,
      meta: this.meta,
      link: [
        {
          rel: `canonical`, href: process.env.desiredCanonical,
        },
      ],
    };
  },
};
