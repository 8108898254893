
export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    isYouTube: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: null,
    },
    filePoster: {
      type: String,
      default: null,
    },
    isBackground: {
      type: Boolean,
      default: false,
    },
    hideMeta: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      video: null,
      videoMeta: null,
      isFullVideo: false,
      showSignup: false,
    };
  },
  computed: {
    youtubeEmbed () {
      return this.isYouTube
        ? `https://www.youtube.com/embed/${this.id}?rel=0&showinfo=0&modestbranding=1&enablejsapi=1`
        : false;
    },
    aspectRatio () {
      return this.isBackground ? `8:3` : `16:9`;
    },
  },
  mounted () {
    if (!this.isYouTube) {
      this.video = this.getVideo(this.id);
    }
  },
  methods: {
    async getVideo () {
      // Get meta

      // Strip non-numeric characters from id (in case url was added accidentally)
      const id = this.id.replace(/\D/g, ``);
      const metaRequest = await this.$getVideoMeta(id);
      if (metaRequest !== null) {
        this.videoMeta = metaRequest;
      }
      // Get file
      const videoRequest = await this.$getVideo(id);
      if (videoRequest !== null) {
        this.video = videoRequest;

        if (this.video?.isFullVideo) {
          this.isFullVideo = true;
          this.$emit(`isFullVideo`, true);
        } else {
          this.isFullVideo = false;
          this.$emit(`isFullVideo`, false);
        }
      } else {
        this.isFullVideo = false;
        this.$emit(`isFullVideo`, false);
      }
    },
  },
};
