
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      default: `center center`,
    },
  },
  computed: {
    type () {
      return this.config.video_type;
    },
    redTVId () {
      return this.config.red_tv_id;
    },
    file () {
      return this.config.video_file;
    },
  },
};
